/**
 * App ajax Object
 *
 * @type object
 */
App.ajax = {

    /**
     * Object properties
     */
    method      : 'GET',
    dataType    : 'json',
    cache       : true,
    returnOrder : 'append',


    /**
     * Constructor Function
     *
     * Boot function
     */
    init : function()
    {
        this.template();
        return this;
    },


    /**
     * Set Method
     */
    setMethod : function(method)
    {
        this.method = method;
        return this;
    },


    /**
     * Set Data Type
     */
    setDataType : function(dataType)
    {
        this.dataType = dataType;
        return this;
    },


    /**
     * Set Cache
     */
    setCache : function(cache)
    {
        this.cache = cache;
        return this;
    },


    /**
     * Shorthand full ajax request
     *
     * @param url       string
     * @param data      object any data you want to pass to the server
     *
     * @return object request
     */
    request : function(url, data)
    {
        return $.ajax({
            url      : url,
            data     : data || null,
            method   : App.ajax.method,
            dataType : App.ajax.dataType,
            cache    : App.ajax.cache
        });
    },


    /**
     * Load function
     *
     * @param url string
     * @param output string
     * @param callback
     */
    load : function(url, output, callback)
    {
        $(output).load(url + ' ' + output + ' > *', callback);
    },


    /**
     * Ajax a template
     */
    template : function()
    {

        $('.ajax-loader').each(function()
        {

            var object = $(this);

            App.ajax.setDataType('html');
            var request = App.ajax.request(object.data('url'));

            // Success function
            request.success(function(data)
            {
                object.html(data);
                $.event.trigger("ajaxLoaderSuccess");
            });

        });

    }
};