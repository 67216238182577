/**
 * App Object
 *
 * @type object
 */
var App = {

    /**
     * Object variables
     */
    a : 'inner',
    e : window,
    viewport: 'small_desktop',
    csrfToken: null,

    loadingEnabled: true,
    requestsWithLoaders: [],


    /**
     * Constructor Function
     *
     * Boot function
     */
    init: function()
    {
        App.handleIE();
        App.handleWidth();
        App.handleHeight();
        App.handleResize();
        App.handleScroll();
        App.ajax.init();
    },


    /**
     * Get the user agent
     *
     * @returns {string}
     */
    getUserAgent: function()
    {
        return navigator.userAgent.toLowerCase();
    },


    /**
     * Is this internet explorer 6
     * @returns {boolean}
     */
    isIE6: function()
    {
        return !!navigator.userAgent.match(/MSIE 6.0/);
    },


    /**
     * Is this internet explorer 7
     *
     * @returns {boolean}
     */
    isIE7: function()
    {
        return !!navigator.userAgent.match(/MSIE 7.0/);
    },


    /**
     * Is this internet explorer 8
     *
     * @returns {boolean}
     */
    isIE8: function()
    {
        return !!navigator.userAgent.match(/MSIE 8.0/);
    },


    /**
     * Is this internet explorer 9
     *
     * @returns {boolean}
     */
    isIE9: function()
    {
        return !!navigator.userAgent.match(/MSIE 9.0/);
    },


    /**
     * Is this internet explorer 10
     *
     * @returns {boolean}
     */
    isIE10: function()
    {
        return !!navigator.userAgent.match(/MSIE 10.0/);
    },


    /**
     * Is this internet explorer 11
     *
     * @returns {boolean}
     */
    isIE11: function()
    {
        return !!navigator.userAgent.match(/MSIE 11.0/);
    },


    /**
     * Handle internet explorer
     */
    handleIE: function()
    {
        if (this.isIE8() || this.isIE9() || this.isIE10()) {
            $('body').addClass('ie');
        }
        if (this.isIE8()) {
            $('body').addClass('ie8');

        } else if(this.isIE9()) {
            $('body').addClass('ie9');

        } else if (this.isIE10()) {
            $('body').addClass('ie10');
        }
    },


    /**
     * Is this a smart device
     *
     * @returns {Array|{index: number, input: string}}
     */
    isSmart: function()
    {
        return App.getUserAgent().match(/(iphone|ipod|ipad)/);
    },


    /**
     * Does this device have touch
     *
     * @returns {boolean}
     */
    hasTouch: function()
    {
        try {
            document.createEvent("TouchEvent");
            return true;

        } catch (e) {
            return false;
        }
    },


    /**
     * Set viewport function
     */
    setViewPort: function()
    {
        if (!('innerWidth' in window)) {
            this.a = 'client';
            this.e = document.documentElement || document.body;
        }
    },


    /**
     * Get window width
     *
     * @returns {*}
     */
    getWidth: function()
    {
        this.setViewPort();
        return this.e[this.a + 'Width'];
    },


    /**
     * Get window height
     *
     * @returns {*}
     */
    getHeight: function()
    {
        this.setViewPort();
        return this.e[this.a + 'Height'];
    },


    /**
     * Handle the window width
     */
    handleWidth: function()
    {
        if (this.getWidth() < 768) {
            this.viewport = 'phone';

        } else if (this.getWidth() > 768 && this.getWidth() < 992) {
            this.viewport = 'tablet';

        } else if (this.getWidth() > 992 && this.getWidth() < 1200) {
            this.viewport = 'small_desktop';

        } else if (this.getWidth() > 1200) {
            this.viewport = 'large_desktop';
        }
    },


    /**
     * Handle container height
     */
    handleHeight: function()
    {
        var height     = App.getHeight() - 8; // 8px Browser padding
        var header     = $('.navbar-nav');
        var breadcrumb = $('#breadcrumb');
        var middle     = $('#middle');
        var footer     = $('#footer');

        if (middle.hasClass('auto-height') && App.getHeight() >= middle.outerHeight()) {
            if (header.length > 0) {
                height = height - header.outerHeight();
            }
            if (breadcrumb.length > 0) {
                height = height - breadcrumb.outerHeight();
            }
            if (footer.length > 0) {
                height = height - footer.outerHeight();
            }

            middle.attr('style', 'min-height:' + height + 'px !important');
        }
    },


    /**
     * Handle the window scroll event
     */
    handleScroll: function()
    {
        var timeout = 0;

        $(window).scroll(function()
        {
            $.event.trigger('app.scroll.start');

            // Wait 1 second until window resize finishes
            if (new Date().getTime() > timeout) {
                /* Put time sensitive calls in here */
                $.event.trigger('app.scroll.end');
                timeout = new Date().getTime() + 1000;
            }

            // Check the scroll position
            if ($(window).scrollTop() > 100) {
                $.event.trigger('app.scroll.tall');

            } else if ($(window).scrollTop() <= 100) {
                $.event.trigger('app.scroll.short');
            }
        });
    },


    /**
     * Handle the window resize event
     */
    handleResize: function()
    {
        var timeout = 0;
        var width   = App.getWidth();
        var height  = App.getHeight();

        $(window).resize(function()
        {
            // Smart device fix
            if (width !== App.getWidth()) {
                $.event.trigger('app.resize.start');

                // Wait 500 milliseconds until window resize finishes
                if (new Date().getTime() > timeout) {
                    width  = App.getWidth();
                    height = App.getHeight();

                    App.handleWidth();
                    $.event.trigger('app.resize.end');

                    timeout = new Date().getTime() + 500;
                }
            }
        });
    },


    /**
     * Notify user
     *
     * @param message
     * @param type
     */
    notifyUser : function(message, type)
    {
        $.bootstrapGrowl(message, {
            ele             : 'body',   // which element to bootstrap component send to
            type            : type,     // (null, 'info', 'error', 'success')
            offset          : {from : 'bottom', amount : 50}, // 'top', or 'bottom'
            align           : 'right',  // ('left', 'right', or 'center')
            width           : 250,      // (integer, or 'auto')
            delay           : 4000,
            allow_dismiss   : true,
            stackup_spacing : 10        // spacing between consecutively stacked growls.
        });
    },


    /**
     * Set CSRF token
     *
     * @param token
     */
    setToken: function(token) {
        this.csrfToken = token;
    },


    /**
     * Get CSRF token
     *
     * @returns {*}
     */
    getToken: function() {
        if (this.csrfToken !== null) {
            return this.csrfToken;
        }
        return $('input[name="_token"]').val();
    },


    /**
     * Show loading function
     */
    showLoading: function()
    {
        $('body').addClass("loading");
    },


    /**
     * Hide loading function
     */
    hideLoading: function()
    {
        $('body').removeClass("loading");
    },


    /**
     * Enable ajax loading handler
     */
    enableLoading: function () {
        this.loadingEnabled = true;
    },


    /**
     * Disable ajax loading handler
     */
    disableLoading: function () {
        this.loadingEnabled = false;
    },


    /**
     * Disable ajax loading handler for next ajax request only
     */
    disableNextLoading: function () {
        this.loadingEnabled = -1;
    },


    /**
     * Convert string to lowercase
     */
    strtolower : function(string)
    {
        return string.toLowerCase();
    },


    /**
     * Convert string to upper case
     */
    strtoupper : function(string)
    {
        return string.toUpperCase();
    },


    /**
     * Converts first letter to upper case
     */
    ucfirst : function(string)
    {
        return string.replace(/(\b)([a-zA-Z])/, function(firstLetter){
            return firstLetter.toUpperCase();
        });
    },


    /**
     * Converts the first letter of each word to upper case
     */
    ucwords : function(string)
    {
        return string.replace(/(\b)([a-zA-Z])/g, function(firstLetter){
            return firstLetter.toUpperCase();
        });
    },


    /**
     * Check if an item is in an array
     *
     * @param needle
     * @param haystack
     * @returns {boolean}
     */
    in_array : function(needle, haystack)
    {
        if ($.inArray(needle, haystack) !== -1) {
            return true;
        }

        return false;
    },


    /**
     * Check if the object value is empty
     *
     * @param value
     * @returns {boolean}
     */
    empty : function(value)
    {
        if (value == '' || value == 'undefined' || value == null || value == 0) {
            return true;
        }

        return false;
    },


    /**
     * Generate a GUID string
     *
     * @returns {string}
     */
    guid: function () {
        function s4() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }

        return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
    }
};


/**
 * Document ready function
 *
 * When the document is ready
 * show the loading bar
 */
$(document).ready(function() {
    App.init();
});


/**
 * Window load functions
 *
 * Once the window has loaded
 * hide the loading bar
 */
$(window).load(function()
{

});


/**
 * Ajax start function
 */
$(document).ajaxSend(function (event, xhr, options)
{
    xhr.guid = App.guid();

    if (App.loadingEnabled === true) {
        App.requestsWithLoaders.push(xhr.guid);
        App.showLoading();
    }
});


/**
 * Ajax stop function
 */
$(document).ajaxComplete(function (event, xhr, options)
{
    // Check if this ajax request was sent with the loader enabled. If it was we'll remove
    // the loader as the request has finished. This check prevents ajax requests
    // hiding each other's loaders if one finishes before the other.
    if (xhr.guid && App.in_array(xhr.guid, App.requestsWithLoaders)) {
        App.hideLoading();
    }

    // Re-enable if only disabled for next request
    if (App.loadingEnabled === -1) {
        App.enableLoading();
    }
});
