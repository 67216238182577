/**
 * Bootstrap Object
 *
 * @type object
 */
App.bootstrap = {

    /**
     * Constructor Function
     *
     * Boot function
     */
    init : function()
    {
        App.bootstrap.handleDatePicker();
        App.bootstrap.handleDateTimePicker();
        App.bootstrap.handleTooltip();
    },


    /**
     * Handle date time picker
     */
    handleDateTimePicker : function()
    {
        if (!$('.datetimepicker').length) {
            return false;
        }

        $('.datetimepicker').datetimepicker({
            format: 'DD/MM/YYYY hh:mm A'
        });
    },


    /**
     * Handle date picker
     */
    handleDatePicker : function()
    {
        // If date picker field is empty set to today's date
        if ($('input.datepicker').val() === '' && $('input.datepicker').data('autofill') !== false) {
            $('input.datepicker').val(moment().format('DD/MM/YYYY'));
        }

        $('input.datepicker').datepicker({
            format    : 'dd/mm/yyyy',
            weekStart : 1

        }).on('changeDate', function(ev)
        {
            $(this).change().blur().datepicker('hide');
        });
    },


    /**
     * Handle tooltip
     */
    handleTooltip : function()
    {
        $('.btn-tooltip').tooltip({
            placement : 'auto',
            container : 'body'
        });
        $('.top-tooltip').tooltip({
            placement : 'top',
            container : 'body'
        });
        $('.right-tooltip').tooltip({
            placement : 'right',
            container : 'body'
        });
        $('.bottom-tooltip').tooltip({
            placement : 'bottom',
            container : 'body'
        });
        $('.left-tooltip').tooltip({
            placement : 'left',
            container : 'body'
        });
    }
};


/**
 * Window load functions
 *
 * Once the window has
 * loaded create the object
 */
$(document).ready(function() {
    App.bootstrap.init();
});


/**
 * Ajax stop functions
 */
$(document).ajaxStop(function(data) {
    App.bootstrap.init();
});
